<template>
  <div class="home-page">
    <MainSection
      class="mt-10 mt-md-14"
      :class="['home-page__section home-page__section_main']"
      :statistics="statistics!"
      @click:open-form="handleShowContactForm(ContactFormRequest.ConnectMedflex)"
    />
    <InsuranceCompaniesSection
      v-hash-intersect="SectionId.ForInsuranceService"
      class="home-page__section"
    />
    <ClinicFeaturesSection
      v-hash-intersect="SectionId.ForClinic"
      class="home-page__section"
    />

    <PartnersSection
      v-hash-intersect="SectionId.Partners"
      class="home-page__section"
    />
    <WidgetSection
      v-hash-intersect="SectionId.Widgets"
      class="home-page__section"
      @click:open-form="handleShowContactForm(ContactFormRequest.InstallingWidgets)"
    />

    <MisListSection
      v-hash-intersect="SectionId.MisList"
      class="home-page__section"
    />

    <ClinicsSection
      v-hash-intersect="SectionId.Clinics"
      class="home-page__section"
      :clinics-count="statistics!.lpusCount"
      @click:open-form="handleShowContactForm(ContactFormRequest.ConnectMedflex)"
    />

    <ClientOnly>
      <ContactFormDialog
        ref="contactFormDialog"
        @send="handleShowContactFormMessage(ContactFormDialogMessageType.Success)"
        @error:limit="handleShowContactFormMessage(ContactFormDialogMessageType.Error)"
      />
      <ContactFormDialogMessage ref="contactFormDialogMessage" />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { ContactFormDialogMessageType, ContactFormRequest, SectionId } from '~/enums'

const { statistics } = useStatisticsData()

const contactFormDialog = ref<{ show: (type: ContactFormRequest) => void } | null>(null)
const contactFormDialogMessage = ref<{ show: (type: ContactFormDialogMessageType) => void } | null>(null)

function handleShowContactForm(type: ContactFormRequest) {
  contactFormDialog.value?.show(type)
}

function handleShowContactFormMessage(type: ContactFormDialogMessageType) {
  contactFormDialogMessage.value?.show(type)
}
</script>

<style scoped lang="scss">
.home-page {
  @media (max-width: $desktop-breakpoint) {
    #{&}__section {
      margin-bottom: 80px;

      &_main {
        margin-bottom: 48px;
      }
    }
  }

  &__section {
    margin-bottom: 120px;

    &_main {
      margin-bottom: 112px;
    }
  }
}
</style>
