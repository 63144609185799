<template>
  <section>
    <h2 class="text-h5 text-md-h4 text-center">
      МедФлекс интегрирует
    </h2>

    <div class="integrations-list ga-4 mt-6 mt-md-10">
      <WithAnimatedArrow
        v-for="(item, index) in ITEMS"
        :key="index"
        class="d-flex flex-column position-relative"
        :to="item.to"
        :prepend-img="{
          src: `/images/integrations/${item.img}.png`,
          smSrc: `/images/integrations/${item.img}_mobile.png`,
          smSrcSet: `/images/integrations/${item.img}_mobile.png 1x, /images/integrations/${item.img}_mobile_3x.png 3x`,
          alt: item.title
        }"
      >
        <p
          class="text-h6 text-md-landing-h5"
          v-text="item.title"
        />

        <p
          class="mt-2 text-body-1 d-none d-md-block"
          v-text="item.subtitle"
        />
      </WithAnimatedArrow>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router'
import WithAnimatedArrow from '~/features/MainPage/ui/WithAnimatedArrow.vue'

const ITEMS = [
  {
    img: 'clinics',
    title: 'Клиники',
    subtitle: 'Расширение возможностей МИС',
    to: {
      name: 'clinics'
    }
  },
  {
    img: 'dms',
    title: 'Страховые',
    subtitle: 'Онлайн-запись в клиники без звонков',
    to: {
      name: 'insurance'
    }
  },
  {
    img: 'services',
    title: 'Сервисы',
    subtitle: 'Данные для оказания услуг клиникам',
    to: {
      name: 'services'
    }
  },
  {
    img: 'government',
    title: 'Государство',
    subtitle: `Отправка документов ${textNoWrap('в РЭМД ЕГИСЗ')}`,
    to: {
      name: 'clinics',
      hash: '#egisz'
    }
  }
] satisfies Array<{
  img: string
  title: string
  subtitle: string
  to: RouteLocationRaw
}>
</script>

<style scoped lang="scss">
@use "sass:map";

.integrations-list {
  display: grid;
  grid-template-columns: 1fr;

  @media #{map.get($display-breakpoints, 'md-and-up')} {
    grid-template-columns: repeat(4, minmax(200px, 270px));
  }
}
</style>
