<template>
  <HomeSection>
    <template
      v-if="$featureFlag('feature_show_new_landing')"
      #title
    >
      Интегрированы {{ misList.length }} МИС
    </template>

    <div
      :class="{
        'd-none d-md-block': $featureFlag('feature_show_new_landing')
      }"
    >
      <MisListTable
        heading-tag="h2"
        :limit="5"
        :hide-heading="$featureFlag('feature_show_new_landing')"
      />
    </div>

    <div
      v-if="$featureFlag('feature_show_new_landing')"
      class="d-md-none d-flex flex-column gr-2"
    >
      <v-sheet
        v-for="(item, index) in sortedDataByLpusCount"
        :key="index"
        min-height="54"
        class="pa-4 rounded-lg d-flex justify-space-between align-center bg-ui-kit-bg-secondary"
      >
        <NuxtLink
          v-if="item.isMedlock"
          target="_blank"
          class="text-primary text-decoration-none text-subtitle-1"
          :href="useRuntimeConfig().public.mlUrl"
        >
          {{ item.name }}
        </NuxtLink>
        <span
          v-else
          class="text-subtitle-1"
          v-text="item.name"
        />
        <span
          class="ml-2 text-body-1 text-ui-kit-text-secondary text-no-wrap"
          v-text="pluralize(item.lpusCount, clinicDeclension)"
        />
      </v-sheet>
    </div>

    <div
      v-if="misList.length"
      class="mt-6 text-center"
    >
      <nuxt-link
        class="d-inline-block"
        :to="{ name: 'mis' }"
      >
        <v-btn class="bg-ui-kit-bg-secondary text-primary">
          Посмотреть все {{ misList.length }} МИС
        </v-btn>
      </nuxt-link>
    </div>
  </HomeSection>
</template>

<script setup lang="ts">
import { isNumber, sortBy } from 'lodash-es'
import { clinicDeclension } from '~/constants'
import type { MisStatisticItemDto } from '~/api/misStatistics'

const { misList } = useMisListData()

const sortedDataByLpusCount = computed(() => {
  const misWithNumberLpus = misList.value.filter(
    ({ lpusCount }) => isNumber(lpusCount)
  ) as Array<MisStatisticItemDto & { lpusCount: number }>

  return sortBy(misWithNumberLpus, 'lpusCount').reverse().slice(0, 5)
})
</script>
