<template>
  <section>
    <h2 class="text-h5 text-md-h4 text-center">
      МедФлекс — это&nbsp;безопасно
    </h2>

    <div class="mt-6 mt-md-10 security-section-items ga-4 ga-md-6">
      <div
        v-for="(item, index) in ITEMS"
        :key="index"
        class="text-body-1 text-md-landing-h6 security-section-items__item d-flex align-center px-6"
      >
        <v-icon
          class="mr-4 mr-md-5"
          icon="ui-icon-lock-key-success"
          size="24"
          color="primary"
        />

        <div>
          {{ item.text }} <nuxt-link
            v-if="item.link"
            class="text-primary text-decoration-none"
            target="_blank"
            :href="item.link.href"
            external
          >
            {{ item.link.text }}
          </nuxt-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
const ITEMS = [
  {
    text: 'Клиники полностью контролируют передачу данных'
  },
  {
    text: 'Чувствительные данные клиник не хранятся на стороне онлайн-сервисов и страховых'
  },
  {
    text: 'МедФлекс получил аттестат соответствия безопасности',
    link: {
      text: 'ФСТЭК',
      href: 'https://fstec.ru/'
    }
  },
  {
    text: 'МедФлекс включён в Единый реестр российского ПО',
    link: {
      text: 'запись №13180 от 11.04.2022',
      href: 'https://reestr.digital.gov.ru/reestr/678111/?sphrase_id=5216073'
    }
  }
] satisfies Array<{
  text: string
  link?: {
    text: string
    href: string
  }
}>
</script>

<style lang="scss">
@use 'sass:map';

.security-section-items {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 100px);

  @media #{map.get($display-breakpoints, 'md-and-up')} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 100px);
  }

  &__item {
    border: 1px solid $t-ui-kit-bg-gray-60;
    border-radius: 16px;
  }
}
</style>
