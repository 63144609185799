<template>
  <section>
    <div class="statistics-section-stats ga-2 ga-md-4">
      <StatisticBlock
        v-for="(stat, index) in stats"
        :key="index"
        :text="stat.name"
        :count="stat.value"
        :month-changes="stat.changes"
      />
    </div>

    <div class="mt-2 mt-md-4 statistics-section-connections ga-2 ga-md-4">
      <WithAnimatedArrow
        v-for="(connection, index) in connections"
        :key="index"
        class="text-h6 text-md-h4 font-weight-semi-bold"
        :to="connection.to"
      >
        {{ connection.text }}
      </WithAnimatedArrow>
    </div>
  </section>
</template>

<script setup lang="ts">
import { integrationDeclension } from '~/constants'
import WithAnimatedArrow from '~/features/MainPage/ui/WithAnimatedArrow.vue'

const { misList } = useMisListData()
const { statistics } = useStatisticsData()

const stats = computed(() => [
  {
    value: statistics.value.lpusCount,
    name: 'клиник подключено',
    changes: statistics.value.lpusCountPerMonth
  },
  {
    value: statistics.value.directAppointmentsCount,
    name: 'сделано онлайн-записей',
    changes: statistics.value.directAppointmentsCountPerMonth
  },
  {
    value: statistics.value.priceListCount,
    name: 'выгружается прайс-листов',
    changes: statistics.value.priceListCountPerMonth
  },
  {
    value: statistics.value.remdDocumentCount,
    name: 'передано документов в РЭМД',
    changes: statistics.value.remdDocumentCountPerMonth
  }
])

const connections = computed(() => [
  {
    text: `${misList.value.length} МИС`,
    to: {
      name: 'mis'
    }
  },
  {
    text: pluralize(statistics.value.integrationsCount, integrationDeclension),
    to: {
      name: 'clinics',
      hash: '#integrations'
    }
  }
])
</script>

<style lang="scss">
@use 'sass:map';

.statistics-section {
  &-stats {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 134px);

    @media #{map.get($display-breakpoints, 'md-and-up')} {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 136px;
    }

    &__item {
      border-radius: 16px;
    }
  }

  &-connections {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 74px);

    @media #{map.get($display-breakpoints, 'md-and-up')} {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 136px;
    }
  }
}
</style>
